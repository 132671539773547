import {CanActivateChildFn,CanActivateFn,CanMatchFn,Router,UrlTree} from '@angular/router';
import {catchError,filter,firstValueFrom,map,Observable,of} from 'rxjs';
import {inject} from '@angular/core';
import {AppService} from '../app.service';
import {USER_ROLE} from '../common/enums/user-role.enum';
import {UserResponseDto} from '../authentication/dto/user-response.dto';
import {HttpErrorResponse} from '@angular/common/http';

export const adminGuardActivate:CanActivateFn=():Promise<boolean|UrlTree>=>{
	return firstValueFrom(inject(AppService).user.pipe(
		filter((user:UserResponseDto|null|undefined):boolean=>user!==undefined),
		map((user:UserResponseDto|null|undefined):boolean|UrlTree=>{
			if(!user){
				return inject(Router).parseUrl('/');
			}else{
				if(user.role?.id!==USER_ROLE.Admin){
					return inject(Router).parseUrl('/error#FORBIDDEN');
				}else{
					return true;
				}
			}
		}),
		catchError((error:HttpErrorResponse):Observable<boolean>=>{
			if(error.status===401){
				return of(true);
			}else{
				return of(true);
			}
		})
	));
};

export const adminGuardMatch:CanMatchFn=async():Promise<boolean|UrlTree>=>{
	return firstValueFrom(inject(AppService).user.pipe(
		filter((user:UserResponseDto|null|undefined):boolean=>user!==undefined),
		map((user:UserResponseDto|null|undefined):boolean|UrlTree=>{
			if(!user){
				return inject(Router).parseUrl('/');
			}else{
				if(user.role?.id!==USER_ROLE.Admin){
					return inject(Router).parseUrl('/error#FORBIDDEN');
				}else{
					return true;
				}
			}
		}),
		catchError((error:HttpErrorResponse):Observable<boolean>=>{
			if(error.status===401){
				return of(true);
			}else{
				return of(true);
			}
		})
	));
};

export const adminGuardActivateChild:CanActivateChildFn=async():Promise<boolean|UrlTree>=>{
	return firstValueFrom(inject(AppService).user.pipe(
		filter((user:UserResponseDto|null|undefined):boolean=>user!==undefined),
		map((user:UserResponseDto|null|undefined):boolean|UrlTree=>{
			if(!user){
				return inject(Router).parseUrl('/');
			}else{
				if(user.role?.id!==USER_ROLE.Admin){
					return inject(Router).parseUrl('/error#FORBIDDEN');
				}else{
					return true;
				}
			}
		}),
		catchError((error:HttpErrorResponse):Observable<boolean>=>{
			if(error.status===401){
				return of(true);
			}else{
				return of(true);
			}
		})
	));
};

